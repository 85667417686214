import React from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './home';
import Impressum from './impressum';
import Datenschutzerklärung from './datenschutz';
import NoPage from './nopage';

function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/startseite" />} /> {/* Redirect from root to /startseite */}
          <Route path="/startseite" element={<Home />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutzerklärung" element={<Datenschutzerklärung />} />
          <Route path="*" element={<NoPage />} /> {/* Catch-all route for undefined paths */}
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
