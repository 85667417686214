import React from "react";
import { Link } from 'react-router-dom';

function Impressum() {
  return (
    <div className="bg-gray-100 text-gray-800 min-h-screen p-20">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md">
        <div className='w-full h-[50px] flex justify-end items-center'>
          <Link to="/startseite" className='hover:scale-125 transition-all hover:underline  bg-blue-900 text-white py-2 px-4 rounded hover:bg-blue-700'>
            Zurück zur Startseite
          </Link>
        </div>
        <h1 className="text-3xl md:text-4xl font-bold mb-6 font-custom">Impressum</h1>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-2">
            Angaben gemäß § 5 TMG
          </h2>
          <p>
            <strong>Gräbner-Keramik</strong>
          </p>
          <p>
            Friederike Gräbner
            <br />
            Max-Weigelt-Straße 24
            <br />
            09221 Neukirchen
          </p>
          <p>Vertreten durch: Friederike Gräbner</p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-2">Kontakt</h2>
          <p>
            Telefon:{" "}
            <a
              href="tel:015204936551"
              className="text-blue-500 hover:text-blue-700"
            >
              015204936551
            </a>
          </p>
          <p>
            E-Mail:{" "}
            <a
              href="mailto:F.Graebner@gmx.de"
              className="text-blue-500 hover:text-blue-700"
            >
              F.Graebner@gmx.de
            </a>
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-2">EU-Streitschlichtung</h2>
          <p>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              className="text-blue-500 hover:text-blue-700"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            .
          </p>
          <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-2">
            Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
          </h2>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-2">Haftung für Inhalte</h2>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </p>
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-2">Haftung für Links</h2>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-semibold mb-2">Urheberrecht</h2>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </p>
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
        </section>
        <div className='h-[50px] md:h-[100px] w-full mt-[50px] bg-blue-900 text-white flex justify-evenly md:text-3xl'>
          <div className='w-[25%] h-full flex justify-center items-center'>
            <Link to="/startseite" className='hover:scale-125 transition-all hover:underline'>
              Startseite
            </Link>
          </div>
          <div className='w-[25%] h-full flex justify-center items-center'>
            <Link to="/datenschutzerklärung" className='hover:scale-125 transition-all hover:underline'>
              Datenschutz
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
