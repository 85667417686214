import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import p1 from './assets/images/p1.jpg';
import p2 from './assets/images/p2.jpg';
import p3 from './assets/images/p3.jpg';
import p4 from './assets/images/p4.jpg';
import p5 from './assets/images/p5.jpg';
import p6 from './assets/images/p6.jpg';
import p7 from './assets/images/p7.jpg';
import p8_1 from './assets/images/p8.jpg';
import p9 from './assets/images/p9.jpg';
import p10 from './assets/images/p10.jpg';
import p11 from './assets/images/p11.jpg';
import p12 from './assets/images/p12.jpg';
import p13 from './assets/images/p13.jpg';
import p8_2 from './assets/images/p8.jpg';
import p15 from './assets/images/p15.jpg';
import p16 from './assets/images/p16.jpg';
import p17 from './assets/images/p17.jpg';
import p19 from './assets/images/p19.jpg';
import p20 from './assets/images/p20.jpg';
import p21 from './assets/images/p21.jpg';
import p22 from './assets/images/p22.jpg';
import cat from './assets/images/cat.jpg';
import apple from './assets/images/apple.jpg';
import apple2 from './assets/images/apple2.jpg';
import door from './assets/images/door.jpg';
import flower from './assets/images/flower.jpg';
import heart from './assets/images/heart.jpg';
import rosehip from './assets/images/rosehip.jpg';
import shelf from './assets/images/shelf.jpg';
import sponge from './assets/images/sponge.jpg';
import entrance from './assets/images/entrance.jpg';
import arrow from './assets/images/arrow.webp';
import threelines from './assets/images/3Lines.webp';
import cross from './assets/images/Cross.webp';
import logo from './assets/images/logo.webp';

import fb from './assets/images/facebook_logo.svg';
import insta from './assets/images/instagram_logo.svg';

function Home() {

  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  const tf_start = useRef(null);
  const tf_workshop = useRef(null);
  const tf_process = useRef(null);
  const tf_about = useRef(null);
  const tf_glazes = useRef(null);
  const tf_dates = useRef(null);
  const tf_socialMedia = useRef(null);
  const tf_impressions = useRef(null);

  const handleClick = (targetRef) => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: 'smooth',
      });

      if (!nav) {
        handleNav();
      }
    }
  };

  function handleScroll() {

    clearTimeout(window.scrollTimeout);
    window.scrollTimeout = setTimeout(() => {
      if (!nav) {
        handleNav();
      }
    }, 1);
  }

  window.addEventListener('scroll', handleScroll);


  return (
    <div>
      <header>
        <div className=" bg-white w-full h-[500px] flex justify-center items-center flex-col mt-[50px]" ref={tf_start}>
          <div className=" w-full h-[400px] flex justify-center items-center flex-row">
            <div className='w-[20%] h-full flex justify-center items-center overflow-hidden'>
              <img className='absolute right-[40%] md:right-[50%] scale-[28%] z-50' src={logo}></img>
            </div>
            <div className='w-[55%] flex flex-col justify-center z-40 bg-white'>
              <h1 className=' font-normal text-3xl text-center'>Friederike Gräbner Keramik</h1>
              <h2 className=' font-normal text-xl text-center'>- Kleinstserien und Einzelstücke -</h2>
            </div>
            <div className='w-[20%] h-full'>
            </div>
            <div id='menu' className={!nav ? ' z-[80] bg-blue-900 text-white flex h-[350px] justify-center items-center fixed w-full left-[0%] ease-in-out duration-500' : ' text-white fixed left-[100%] transition ease-in-out duration-500'}>
              <button className='h-[300px] flex flex-col justify-between items-center' onClick={handleClick}>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200' onClick={() => handleClick(tf_workshop)}>Werkstatt</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200' onClick={() => handleClick(tf_process)}>Arbeitsweise</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200' onClick={() => handleClick(tf_about)}>Die Töpferin</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200' onClick={() => handleClick(tf_glazes)}>Glasuren</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200' onClick={() => handleClick(tf_dates)}>Markttermine</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200' onClick={() => handleClick(tf_socialMedia)}>Virtuelles Schaufenster</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200' onClick={() => handleClick(tf_impressions)}>Impressionen</h2>
              </button>
            </div>
            <div className='z-30 flex justify-evenly flex-col items-center font-body absolute border-[2px] border-gray-600 bg-white top-[380px] h-[350px] w-[60%] md:w-[35%] md:bottom-[25%] mb-[20px]'>
              <p className='font-custom'>Leise dreht sich</p>
              <p className='font-custom'>das Rad der Töpferin:</p>
              <p className='font-custom'>zwei Hände</p>
              <p className='font-custom'>lesen die Zeichen</p>
              <p className='font-custom'>der Erde</p>
              <p className='font-custom'>umkreisen die Zeit</p>
              <p className='font-custom'>und formen den Raum.</p>
              <p className='text-sm font-custom'>B. Baumgartner</p>
            </div>
          </div>
          <div className=" w-full h-[100px] flex justify-end items-center flex-row">
            <div onClick={handleNav} className=' z-[90] flex mr-3 cursor-pointer hover:scale-110 ease-in-out duration-200'>
              {!nav ? <img src={cross} className='my-auto w-[40px]' /> : <img src={threelines} className='my-auto w-[40px]' />}
            </div>
          </div>
        </div>
      </header>
      <div>
        <div className='pointer-events-none z-[80] w-full h-[100px] bottom-10 left-[-2%] fixed flex justify-end items-end cursor-pointer'>
          <div className='pointer-events-auto w-[60px] h-[60px] flex justify-center items-center bg-blue-900 hover: hover:bg-blue-700 rounded-full hover:scale-110 transition ease-in-out duration-200'><img className='w-[50px] h-[50px]' src={arrow} onClick={() => handleClick(tf_start)}></img></div>
        </div>
        <div className='h-full w-full sm:p-[2%] md:p-[100px] flex flex-col items-center bg-body bg-no-repeat bg-cover bg-center bg-fixed'>
          <img className='z-40 w-[60%] mt-[350px]' src={p10}></img>
          <div className='w-[100%] md:w-[60%] mt-[200px]  bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_workshop} className='text-2xl text-center mb-10'>Meine Werkstatt</h2>
            <p className='text-center'>Sie empfängt mich in den Feldsteinwänden des 250-jährigen Bauernhauses einen jeden Morgen und beherbergt die Töpferscheiben. Fertige und unfertige Töpfe. Meine Rohstoffe und tausende Ideen. Sehnsüchte und Hoffnungen.</p>
          </div>
          <img className='z-40 w-[60%] mt-[50px]' src={p22}></img>

          <div className='w-[100%] md:w-[60%] mt-[200px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_process} className='text-2xl text-center mb-10'>Die Arbeitsweise</h2>
            <p className='text-center mb-5'>Meine Gefäße sind auf der Töpferscheibe gedreht, oft verformt oder mit kleinen Blaudruckstempelmustern versehen. Spuren der Herstellung sollen auf den fertigen Stücken verbleiben, die Oberflächen sind lebendig und teilweise nicht wiederholbar.</p>
            <p className='text-center'>All meine Glasuren sind aus Aschen, verschiedenen Lehmen, Tonerden, Gesteinsmehlen und Farboxiden selbst gefertigt.</p>
          </div>
          <img className='z-40 w-[60%] mt-[50px]' src={sponge}></img>
          <img className='z-40 w-[60%] mt-[50px]' src={shelf}></img>

          <div className='w-[100%] md:w-[60%] mt-[200px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_about} className='text-2xl text-center mb-10'>Die Töpferin</h2>
            <p className='text-center'>Ich, Jahrgang 1970, bin über viele Umwege zum Töpferhandwerk gekommen. Als Mutter 2er wunderbarer Töchter lebe ich mit meinem Mann, Katzen, Hund und Pferd im ländlichen Erzgebirge.</p>
          </div>
          <div className='w-[100%] md:w-[60%] mt-[100px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_glazes} className='text-2xl text-center mb-10'>Die Glasuren</h2>
            <p className='text-center mb-10'>Grün: matt bis seidenmatt, kann teilweise ins Orange bis Braun gehen</p>
            <p className='text-center mb-10'>Blau: seidenmatt, kann teilweise Braun changieren</p>
            <p className='text-center mb-10'>Weißbeige: seidenmatt bis glänzend</p>
            <p className='text-center mb-10'>Blau: seidenmatt, kann teilweise Braun changieren</p>
            <p className='text-center mb-10'>Braun: eine seidenmatte Lehmglasur</p>
            <p className='text-center mb-10'>Ockergelb: seidenmatt mit glänzenden Ausscheidungen</p>
          </div>
          <div className='w-[100%] md:w-[60%] mt-[100px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_dates} className='text-2xl text-center mb-10'>Meine Markttermine</h2>
            <p className='text-center mb-10'>25./26. Mai Töpfermarkt in Gera</p>
            <p className='text-center mb-10'>1./2. Juni Töpfermarkt auf Schloss Köthen</p>
            <p className='text-center mb-10'>29./30. Juni Töpfermarkt Wasserschloss Klaffenbachu</p>
            <p className='text-center mb-10'>26. bis 28. Juli Keramikmarkt in Bad Schandau</p>
            <p className='text-center mb-10'>17./18. August Töpfermarkt in Römhild</p>
            <p className='text-center mb-10'>14./15. September Töpfermarkt in Templin</p>
            <p className='text-center mb-10'>5./6. Oktober Kunsthandwerkermarkt in Naumburg</p>
            <p className='text-center mb-10'>26./27. Oktober Töpfermarkt in Plauen</p>
          </div>
          <div className='w-[100%] md:w-[60%] mt-[100px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_socialMedia} className='text-2xl text-center mb-20'>Mein virtuelles Schaufenster</h2>
            <div className='w-[100%] flex justify-center items-center'>
              <div className='w-[40%] flex justify-center items-center'>
                <a href="https://www.facebook.com/people/Friederike-Gr%C3%A4bner-Keramik/100063538120427/" target='blank' className='flex justify-center'><img className='w-[40%] h-[40%] hover:scale-105 transition duration-200 ease-in-out' src={fb}></img></a>
              </div>
              <div className='w-[40%] flex justify-center items-center'>
                <a href="https://www.instagram.com/friederikegraebner/" target='_blank' className='flex justify-center'><img className='w-[40%] h-[40%] hover:scale-105 transition duration-200 ease-in-out' src={insta}></img></a>
              </div>
            </div>
          </div>

          <div className='w-[100%] md:w-[60%] mt-[100px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_impressions} className='text-3xl text-center'>Impressionen</h2>
          </div>
          <img className='z-40 w-[60%] mt-[50px]' src={apple2}></img>
          <img className='z-40 w-[60%] mt-[50px]' src={apple2}></img>
          <img className='z-40 w-[60%] mt-[50px]' src={cat}></img>
          <img className='z-40 w-[60%] mt-[50px]' src={door}></img>
          <img className='z-40 w-[60%] mt-[50px]' src={rosehip}></img>
          <img className='z-40 w-[60%] mt-[50px]' src={rosehip}></img>
          <img className='z-40 w-[60%] mt-[50px]' src={entrance}></img>
          <img className='z-40 w-[60%] mt-[50px] mb-[50px]' src={heart}></img>
        </div>
        <div className='h-[50px] md:h-[100px] w-full bg-blue-900 text-white flex justify-evenly md:text-3xl'>
          <div className='w-[25%] h-full flex justify-center items-center'>
            <Link to="/impressum" className='hover:scale-125 transition-all hover:underline'>
              Impressum
            </Link>
          </div>
          <div className='w-[25%] h-full flex justify-center items-center'>
            <Link to="/datenschutzerklärung" className='hover:scale-125 transition-all hover:underline'>
              Datenschutz
            </Link>
          </div>
        </div>
      </div>
      <footer>
      </footer>
    </div>
  );
}

export default Home;
